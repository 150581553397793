<!-- 用户提交组件 -->
<template>
  <div class="container">
    <div class="userSubmit">
      <p class="title">截图</p>
      <div class="body">
        <el-upload
          :class="{ hide: hideUploadAdd }"
          size="mini"
          :limit="5"
          action="/api/cos/upload"
          list-type="picture-card"
          :on-success="successChange"
          :on-change="handleAddChange"
          :on-preview="handlePictureCardPreview"
          :headers="uploadHeaders"
          :data="{ folder: 'receive' }"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
      <div class="link">
        <span>文章链接</span>
        <el-input size="mini" v-model="annex"></el-input>
      </div>
    </div>
    <el-row style="text-align: right">
      <el-button
        size="mini"
        style="background-color: #5874ef; color: #fff"
        @click="usercommit"
        >提交</el-button
      >
    </el-row>
  </div>
</template>

<script>
import { usercommit } from "@/api/user";
import { debounce } from "lodash";
import store from "@/store";
import router from "@/router";
export default {
  components: {},
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      hideUploadAdd: false,
      uploadHeaders: { "x-auth-token": localStorage.getItem("token") },
      fileList: [],
      annex: "",
    };
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {
    taskInfo() {
      return store.state.task.taskInfo;
    },
  },
  methods: {
    handleRemove(file, fileList) {
      this.fileList = this.fileList.filter(function (item) {
        return item !== file.response;
      });

      if (fileList.length <= 5) {
        this.hideUploadAdd = false;
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 上传change事件
    handleAddChange(file, fileList) {
      // 图片大小限制
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 20MB!");
        // 发现某一张超过大小就从列表移除
        fileList.splice(-1, 1);
      } else {
        this.fileListAdd = fileList;
      }

      // 上传文件>=限制个数时隐藏上传组件
      if (fileList.length >= 5) {
        this.hideUploadAdd = true;
      }
    },
    successChange(response, file, fileList) {
      this.fileList.push(response.data);
    },
    // 防抖
    usercommit: debounce(function () {
      usercommit({
        description: "",
        annex: this.annex,
        picUrls: this.fileList,
        missionReceiveId: this.taskInfo.missionReceiveId,
      }).then((res) => {
        if (res.code === 1000) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          router.push({
            path: "/taskhall",
          });
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    }, 500),
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .userSubmit {
    flex: 1;
    overflow: auto;

    .title {
      font-size: 0.9rem;
      color: #999999;
      line-height: 200%;
    }

    .link {
      padding-top: 10px;

      & :first-child {
        font-size: 0.8rem;
      }

      & :last-child {
        width: 10rem;
        margin-left: 10px;
        text-decoration: underline;
        color: blue;
      }
    }
  }
}
</style>
<style lang="scss" >
.el-upload--picture-card {
  height: 6rem !important;
  width: 6rem;

  & i {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 6rem !important;
  height: 6rem !important;
}

.hide .el-upload--picture-card {
  display: none;
}
</style>